<template>
  <div>
   
    <v-container fluid>
        <full-loader v-show="loading"></full-loader>
      <!-- breadcrum and previous router -->
      <div class="breadcrumb-header">
        <v-btn text small icon color="primary" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbItems" divider="/"></v-breadcrumbs>
      </div>
      <div class="download-contain">
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click="download_csv"
        >
          Download
          <v-icon right dark>mdi-cloud-download</v-icon>
        </v-btn>
      </div>
      <modal
      v-if="isShowModal"
      @close="close"
      @getdate="getdate"
    ></modal>

      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :pagination-options="{
        enabled: true,
  }"
        :rows="rows"
        :columns="columns"
        :sort-options="{
    enabled: false}"
      />
    </v-container>
  </div>
</template>
<script>
import Mixins from "@/mixin";
import moment from "moment";
import AdminAPI from "@/services/admin_api";
import Modal from "../../src/components/daterange.vue";
import FullLoader from "../../src/components/full-loader.vue";

export default {
  name: "tnb-logs",
  mixins: [Mixins],
   components: {
    modal: Modal,
     "full-loader": FullLoader,
   },
  data() {
    return {
      isShowModal: false,
      isShowloading:false,
      lock_download: false,
      breadcrumbItems: [
        {
          text: "System logs",
          disabled: false,
          to: { name: "email-logs" },
          exact: true
        }
      ],
      isLoading: false,
      columns: [
        {
          label: "User",
          field: "user",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Search user", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "URL",
          field: "url",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Search URL", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Message",
          field: "message",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Search Message", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Created At",
          field: "createdAt",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Search Created At", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Access Status",
          field: "access_status",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Search Access Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Browser",
          field: "brow",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Search Browser", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "OS",
          field: "os",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Search OS", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "IP",
          field: "ip",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Search OS", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          }
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "user", // example: 'name'
            type: "desc" // 'asc' or 'desc'
          }
        ],
        page: 1,
        perPage: 10,
       loggedUser: {}
      },
      loading: false
    };
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    imagesForChooseModal() {
      //console.log("imagesForChooseModal");
    },

    close() {
      this.isShowModal = false;
    },

    async getdate(getdate) {     
      let loggedUser = JSON.parse(localStorage.getItem("userData"));
      this.isShowloading = true;
      this.loading = true;
      // console.log("getdate=====>",getdate,this.serverParams.columnFilters);
      var start_date  = getdate.startDate;
      var end_date  = getdate.endDate;
      this.serverParams.columnFilters['startDate']=start_date;
      this.serverParams.columnFilters['endDate']=end_date;
      // console.log("this.serverParams.columnFilters =====>",this.serverParams.columnFilters);
      this.lock_download = true;
      this.serverParams.loggedUser = loggedUser;
      var data = await AdminAPI.post(
        `accesslogsDownload`,
        this.serverParams,
        true
      );
      this.loading = false;
      data = data.data.rows;
      var csv = "user,url,message,createdAt,access_status,browser,os\n";
      var data = data;

      for (const row of data) {
        csv += row.join(",");
        csv += "\n";
      }
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = "logs.csv";
      hiddenElement.click();
      this.lock_download = false;
      this.isShowloading = false
    },

    save(images) {
      console.log(images);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field
          }
        ]
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      // console.log(params);
      this.updateParams(params);
      this.loadItems();
    },

    async download_csv() {
      // console.log("Download CSV====>");
      this.isShowModal = true;

      // this.lock_download = true;
      // var data = await AdminAPI.post(
      //   `accesslogsDownload`,
      //   this.serverParams,
      //   true
      // );
      // data = data.data.rows;
      // var csv = "user,url,message,createdAt,access_status,browser,os\n";
      // var data = data;

      // for (const row of data) {
      //   csv += row.join(",");
      //   csv += "\n";
      // }
      // var hiddenElement = document.createElement("a");
      // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      // hiddenElement.target = "_blank";
      // hiddenElement.download = "logs.csv";
      // hiddenElement.click();
      // this.lock_download = false;
    },

    // load items is what brings back the rows from server
    async loadItems() {
      this.loading = true;
      let loggedUser = JSON.parse(localStorage.getItem("userData"));
      this.serverParams.loggedUser = loggedUser;
      var data = await AdminAPI.post(`accesslogs`, this.serverParams, true);
      this.loading = false;
      data = data.data;
      this.totalRecords = data.totalRecords;
      this.rows = data.rows;
      for (const key in this.rows) {
        if (this.rows.hasOwnProperty(key)) {
          const element = this.rows[key];
          var localTime = moment(new Date(element.createdAt)).format("YYYY-MM-DD HH:mm:ss");
          // .utc(`${element.createdAt}`)
          // .format("YYYY-MM-DD HH:mm:ss");
          // localTime = localTime + " UTC";
          // localTime = moment(localTime).format("YYYY-MM-DD HH:mm:ss");
          this.rows[key].createdAt = localTime;
        }
      }
    }
  },
  async mounted() {
    await this.loadItems();
  }
};
</script>

<style scoped>
.download-contain {
  float: right;
}

</style>
