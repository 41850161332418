<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-choose-image">
                    <div class="modal-header-choose-image">
                        <slot name="header">
                            <div style="float: left; cursor:pointer;">
                                <h6>Download System Logs</h6>
                            </div>                        
                            <div style="float: right; cursor:pointer;" @click="close()">
                                <i class="fas fa-times mr-2"></i>
                            </div>
                        </slot>
                    </div>
                    <div style="overflow: hidden;">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 mt-2">
                                  Select Date Range : 
                                  <date-range-picker
                                    :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
                                    :opens="opens"
                                    :autoApply="autoApply"
                                    :showDropdowns="showDropdowns"
                                    :singleDatePicker="singleDatePicker"
                                    @select="dateselected"
                                    v-model="dateRange"                                   
                                  ></date-range-picker>
                                  <a v-if="show_clear" @click="clearfield()" href="javascript:;" class="clear_icon"> clear </a>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12 mb-2 text-end">
                                <button class="btn btn-danger mr-2" type="button"  @click="close()"> Cancel </button>
                                <v-btn 
                                type="button" 
                                @click="getdate()"                              
                                :disabled="lock_download"
                                color="blue-grey"
                                class="white--text btn btn-sm"
                                >  Download <v-icon right dark>mdi-cloud-download</v-icon> </v-btn>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import '.../../vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    components: { DateRangePicker },

    data() {
        return {
          lock_download: true,
          isShowloading:false,
          show_clear:false,
          opens:"inline",
          showDropdowns:true,
          singleDatePicker:false,
          autoApply:true,
          dateRange: {
          startDate: null,
          endDate: null,
        },
        }
    },
    computed: {},

    watch: {},

    methods: {

      close() {
      console.log("close modal");
      console.log(this.images);
      this.$emit("close");
      },

      clearfield() {
        this.dateRange.startDate=null;
        this.dateRange.endDate=null;
        this.show_clear=false;
        this.lock_download = true;
      },
      
      dateselected() {
        console.log("date clicked",this.dateRange);
        this.lock_download = false;
        this.show_clear=true;
      },

      getdate() {        
        this.isShowloading = true;
        console.log("dateRange");
        console.log(this.dateRange);
        this.lock_download = false;
        this.$emit("getdate",this.dateRange);
      },
      

    }
}
</script>

<style scoped src="@/../public/bootstrap/css/bootstrap.min.css"></style>
<style scoped src="@/../public/css/fontawesome-pro-5.1.1-web/css/all.css"></style>
<style scoped>

.clear_icon {
  position: absolute;
  color: #007bff;
  right: 20px;
  top: 42px;
  font-size: 13px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  height: 100%;
  width: 80%;
  position: relative;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-choose-image {
  height: 450px;
  width: 740px;
  position: relative;
  margin: 0px auto;
  /* padding: 20px 30px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  padding: 0 0 5px 0 !important;
}
.modal-header-choose-image {
  background: #efeff4;
  padding: 10px 20px 30px 20px!important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* border-bottom: 1px solid #444 */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 5px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>